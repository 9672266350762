const GalleryDescriptionMap = {
  BranchControlledItems: "Branch controlled items",
  BranchControlledItems2: "More branch controlled items",
  BranchEmployees: "Branch employees",
  BranchPaymentMethods: "Branch payment methods",
  BranchShift: "Branch shift",
  BranchShift2: "More Branch shifts",
  BranchVendor: "Branch vendors",
  EndOfDaySummary: "End of day summary report",
  FuelCost: "Fuel costs",
  FuelCreditSales: "Fuel credit sales",
  FuelTank: "Fuel tank",
  Login: "Login",
};
export default GalleryDescriptionMap;
