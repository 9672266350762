import "./App.css";
import Layout from "./layout/layout";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Advantages from "./pages/advantages";
import Pricing from "./pages/pricing";
import ContactUs from "./pages/contactUs";
import AboutUs from "./pages/aboutUs";
import OurSolutions from "./pages/ourSolutions";
import ScreenShots from "./pages/screenShots";
import Clients from "./pages/clients"
import HowItWorks from "./pages/howItWorks";
import FaqPage from "./pages/faqPage";
import PrivacyPage from "./pages/privacyPage";
import React from "react";

function App() {
  return (
    <Layout>
      <Routes>
        <Route>
          <Route path="/" element={<Home />}></Route>
          <Route path="/advantages" element={<Advantages />}></Route>
          <Route path="/how-it-works" element={<HowItWorks />}></Route>
          <Route path="/screen-shots" element={<ScreenShots />}></Route>
          <Route path="/our-solutions" element={<OurSolutions />}></Route>
          <Route path="/pricing" element={<Pricing />}></Route>
          <Route path="/about-us" element={<AboutUs />}></Route>
          <Route path="/faq" element={<FaqPage />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/clients" element={<Clients />}></Route>
          <Route path="/privacy" element={<PrivacyPage />}></Route>
        </Route>
      </Routes>
    </Layout>
  );
}

export default App;
