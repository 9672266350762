import { useTranslation } from "react-i18next";
import React from "react";

function AboutUs() {
  const { t } = useTranslation();
  return (
    <div className="container mb-5">
      <div className="container w-75">
        <div>
          <h1 className="mb-5 header-font">{t("body.aboutUs.title")}</h1>
          <div className="d-flex justify-content-center">
            <p className="paragraph-font justify-text">
              {t("body.aboutUsParagraph")}
            </p>
          </div>
        </div>
        <div>
          <h5 className="sub-header-font">{t("body.vision")}</h5>
          <div className="d-flex justify-content-center">
            <p className="paragraph-font justify-text">
              {t("body.visionParagraph")}
            </p>
          </div>
        </div>
        {/*<div>
          <h5 className="sub-header-font">{t("body.faq")}</h5>
          <div>
            <p className="paragraph-font">{t("body.faqParagraph")}</p>
          </div>
        </div>*/}
      </div>
    </div>
  );
}
export default AboutUs;
