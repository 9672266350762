import AdvantageList from "../components/advantages/advantageList";
import { useTranslation } from "react-i18next";
import Video from "../components/video/video";
import React from "react";

function Home() {
  const { t } = useTranslation();
  return (
    <div className="container mb-5">
      <div className="d-md-flex">
        <div>
          <img className="h-100 img-fluid" src="./../../gasStation.jpg" alt="gad station"></img>
        </div>
        <div className="main-back-ground d-flex flex-column justify-content-center">
          <h1 className="pe-5 ps-5 main-header-font text-center pt-xl-0 pt-3">
            {t("body.home.title")}
          </h1>
          <br />
          <p className="main-paragraph-font text-center">
            {t("body.home.subTitle")}
          </p>
        </div>
      </div>
      <div className="mt-5 mb-5 text-center">
        <h2 className="header-font">{t("body.home.joinUs")}</h2>
      </div>
      <div className="mt-5 mb-5 text-center">
        <h5 className="paragraph-font">{t("body.home.ourSolutions")}</h5>
      </div>
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                      <Video youtubeID="Ve1T6rGCdbw" />
          </div>
        </div>
      </div>

      <AdvantageList />
    </div>
  );
}

export default Home;
