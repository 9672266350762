import React from 'react'
import { useTranslation } from "react-i18next";

function PrivacyPage() {
  const { t } = useTranslation();
  return (
    <div className="container mb-5">
      <div className="container w-75">
        <div>
          <h1 className="mb-5 header-font">{t("Privacy Policy")}</h1>
          <div className="d-flex justify-content-center">
            <p className="paragraph-font justify-text">
            {t(`This Privacy Policy outlines how Rekonsi LLC ("Rekonsi", "we", "us" or "our") collects, uses, shares, 
            and protects your personal information when you use our reconciliation software, Rekonsi ("Software"). 
            We are committed to protecting your privacy and ensuring that your personal information is secure. 
            By using our Software, you consent to the collection, use, and disclosure of your personal information as described in this Privacy Policy.`)}
            </p>
          </div>
        </div>
        <div>
          <h5 className="sub-header-font">{t("Information We Collect")}</h5>
          <div className="d-flex justify-content-center">
            <p className="paragraph-font justify-text">
            {t(`We collect information that you provide to us when you use our Software, such as your name, 
            email address, and any other information you provide to us. We also collect information about 
            your use of our Software, such as your device type, IP address, and browser type.`)}
            </p>
          </div>
        </div>
        <div>
          <h5 className="sub-header-font">{t("How We Use Your Information")}</h5>
          <div className="d-flex justify-content-center">
            <p className="paragraph-font justify-text">
            {t(`We use your personal information to provide you with our Software, to communicate with you 
            about our Software, and to improve our Software. We may also use your personal information to 
            send you marketing communications about our Software, but you can opt-out of these communications at any time.`)}
            </p>
          </div>
        </div>
        <div>
          <h5 className="sub-header-font">{t("How We Protect Your Information")}</h5>
          <div className="d-flex justify-content-center">
            <p className="paragraph-font justify-text">
            {t(`We take reasonable steps to protect your personal information from unauthorized access, use, or 
            disclosure. We use industry-standard security measures to protect your personal information, 
            including encryption and secure storage.`)}
            </p>
          </div>
        </div>
        <div>
          <h5 className="sub-header-font">{t("Your Data is Not Legible Unless Opened by Your Account Only")}</h5>
          <div className="d-flex justify-content-center">
            <p className="paragraph-font justify-text">
            {t(`We understand the importance of data privacy and we make sure that your data is not legible 
            unless opened by your account only. We take steps to ensure that your data is encrypted and 
            stored securely, and we do not share your personal information with third parties without your consent.`)}
            </p>
          </div>
        </div>
        <div>
          <h5 className="sub-header-font">{t("Sharing Your Information")}</h5>
          <div className="d-flex justify-content-center">
            <p className="paragraph-font justify-text">
            {t(`We may share your personal information with third-party service providers who assist us in 
            providing our Software, such as hosting providers and payment processors. We may also share 
            your personal information if we are required to do so by law or if we believe that it is necessary to 
            protect our rights, property, or safety.`)}
            </p>
          </div>
        </div>
        <div>
          <h5 className="sub-header-font">{t("Your Choices")}</h5>
          <div className="d-flex justify-content-center">
            <p className="paragraph-font justify-text">
            {t(`You can choose not to provide us with certain information, but this may limit your ability to use 
            our Software. You can also opt-out of receiving marketing communications from us by following 
            the instructions in the communication.`)}
            </p>
          </div>
        </div>
        <div>
          <h5 className="sub-header-font">{t("Changes to Our Privacy Policy")}</h5>
          <div className="d-flex justify-content-center">
            <p className="paragraph-font justify-text">
            {t(`We may update this Privacy Policy from time to time. If we make any material changes to this 
            Privacy Policy, we will notify you by email or by posting a notice on our Software.`)}
            </p>
          </div>
        </div>
        <div>
          <h5 className="sub-header-font">{t("Contact Us")}</h5>
          <div className="d-flex">
            <p className="paragraph-font justify-text">
            {t(``)}
            If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@rekonsi.com" class="text-decoration-none text-muted">info@rekonsi.com</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPage