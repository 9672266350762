import { useTranslation } from "react-i18next";
import React from "react";

function Faq(props) {
  const { t } = useTranslation();
  const numberOfQuestions = 14;
  const array = [...Array(numberOfQuestions).keys()];
  return (
    <div className="d-flex flex-column">
      {array.map((x) => (
        <div key={x}>
          <details className="mb-4 mt-3">
            <summary className="faq-summary faq-hover">
              {t("body.faqPage.q" + (x + 1))}
            </summary>
            <p className="paragraph-font justify-text">
              {t("body.faqPage.a" + (x + 1))}
            </p>
          </details>
          <hr/>
        </div>
      ))}
    </div>
  );
}

export default Faq;
