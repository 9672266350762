import ImageGallery from "react-image-gallery";
import { useTranslation } from "react-i18next";
import GalleryDescriptionMap from "../maps/galleryDescription.js";
import React from 'react'

function ScreenShots() {
  const { t } = useTranslation();
  function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }
  const imagePaths = importAll(
    require.context("../../public/gallery", false, /\.(png|jpe?g|svg)$/)
  );
  let images = [];
  for (let [key, value] of Object.entries(imagePaths)) {
    images.push({ description: GalleryDescriptionMap[key.split(".")[0]], original: value, thumbnail: value });
  }

  return (
    <div className="container mb-5">
      <h2 className="mb-5 header-font">{t("body.screenShots")}</h2>
      <div className="pt-5 d-flex justify-content-center">
        <div>
          <ImageGallery items={images} showBullets={true} showIndex={true}/>
        </div>
      </div>
    </div>
  );
}

export default ScreenShots;
