import { useTranslation } from "react-i18next";
import React from "react";

function Pricing() {
  const { t } = useTranslation();
  return (
    <div className="container mb-5">
      <h1 className="mb-5 header-font">{t("body.pricing")}</h1>
      <div >
      
        {/* Old Script: */}
        {/* <stripe-pricing-table pricing-table-id="prctbl_1MogZqHLukM44hPnWqI8IzRV"
          publishable-key="pk_test_51MTrJjHLukM44hPnjbA0oH77aajn6XDfQzNgOLEd7LnQBYlUSjtcW8qmmujwSlHquvTIQ11XyH2bgpyE7HQzbrTV00LGNpYbDf">
        </stripe-pricing-table> */}

        {/* New Script: 9/18/2023 */}
        {/* <stripe-pricing-table pricing-table-id="prctbl_1NrcYTHLukM44hPnsvgFAtRZ"
          publishable-key="pk_live_51MTrJjHLukM44hPnVksuFzrN5D9pcKEz2FLxKkZk09qpo27t9SQueNkj4rED93UlVBC5MLRrQ2evF0oBI0aWc0Jw00VOLnBvj8">
        </stripe-pricing-table> */}
        
        {/* New Script: 11/02/2023 */}
        <stripe-pricing-table pricing-table-id="prctbl_1O7z5KHLukM44hPnfLCFGNT8"
          publishable-key="pk_live_51MTrJjHLukM44hPnVksuFzrN5D9pcKEz2FLxKkZk09qpo27t9SQueNkj4rED93UlVBC5MLRrQ2evF0oBI0aWc0Jw00VOLnBvj8">
        </stripe-pricing-table>
        
      </div>
    </div>
  );
}

export default Pricing;
