import AdvantageItem from "./advantageItem";
import React from "react";

/*
{
    id: 3,
    name: "Rich data driven dashboards",
    description: "View, compare, analyze and manipulate data as you desire",
    image: "./../../../advantages/dashBoard.jpg",
  }
  */
const DATA = [
  {
    id: 1,
    name: "body.advantages.name4",
    description:
    "body.advantages.description4",
    image: "./../../../advantages/reconcillation.png",
  },
  {
    id: 2,
    name: "body.advantages.name2",
    description: "body.advantages.description2",
    image: "./../../../advantages/control.png",
  },
  {
    id: 3,
    name: "body.advantages.name1",
    description: "body.advantages.description1",
    image: "./../../../advantages/allReports.jpg",
  },
  {
    id: 4,
    name: "body.advantages.name5",
    description: "body.advantages.description5",
    image: "./../../../advantages/everywhere.png",
  },
  {
    id: 5,
    name: "body.advantages.name6",
    description: "body.advantages.description6",
    image: "./../../../advantages/online.png",
  },
];
function AdvantageList(props) {
  return (
    <div>
      {DATA.map((advantage) => (
        <AdvantageItem key={advantage.id} advantageItem={advantage} />
      ))}
    </div>
  );
}

export default AdvantageList;
