import React from "react";
import MainNavigation from "./mainNavigation";
import Footer from "./footer";

function Layout(props) {
  return (
    <div className="d-flex flex-column min-vh-100">
      <MainNavigation />
      <main className="container">{props.children}</main>
      <Footer/>
    </div>
  );
}

export default Layout;
