import { useTranslation } from "react-i18next";
import React from "react";

function OurSolutions() {
  const { t } = useTranslation();
  return (
    <div className="container mb-5">
      <h1 className="mb-5 header-font">{t("body.ourSolutions")}</h1>
    </div>
  );
}
export default OurSolutions;
