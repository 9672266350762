import { useTranslation } from "react-i18next";
import Faq from "../components/faqs/faq";
import React from "react";

function FaqPage() {
  const { t } = useTranslation();
  return (
    <div className="container mb-5">
      <div className="container w-75">
        <h1 className="mb-5 header-font">{t("body.faqPage.title")}</h1>
        <div className="d-flex justify-content-center">
          <Faq />
        </div>
      </div>
    </div>
  );
}
export default FaqPage;
