import React from "react";

function Video(props) {
  return (
    <div className="ratio ratio-16x9">
      <iframe
        className="video"
        title="Youtube player"
        sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
        src={`https://youtube.com/embed/${props.youtubeID}?autoplay=0`} allowFullScreen
      ></iframe>
    </div>
  );
}

export default Video;
