import { useTranslation } from "react-i18next";
import React from "react";

function Clients() {
  const { t} = useTranslation();
  return (
    <div className="container mb-5">
      <h1 className="mb-5 header-font">{t("body.clients")}</h1>
      <div className="pt-xl-5 pt-lg-3 pt-md-2 pt-0">
        {/*<ClientList />
        
        <div className="container">
          <div className="row justify-content-center">
            <img className="col-3 p-3 img-fluid" src="./../../logos/royalLogo.jpg"></img>
            <img className="col-3 p-3 img-fluid" src="./../../logos/rioGrandeLogo.jpg"></img>
            <img className="col-3 p-3 img-fluid" src="./../../logos/paseosLogo.jpg"></img>
            <img className="col-3 p-3 img-fluid" src="./../../logos/palmasLogo.jpg"></img>
          </div>
          <div className="row justify-content-center">
            <img className="col-3" src="./../../logos/turboLogo.jpg"></img>
            <img className="col-3" src="./../../logos/amalLogo.jpg"></img>
            <img className="col-3" src="./../../logos/aguasLogo.jpg"></img>
            <img className="col-3" src="./../../logos/berrinLogo.jpg"></img>
          </div>
        </div>
        
        */}
        {/*
        <div className="mb-5 d-xl-flex justify-content-between">
          <img
            className="p-2 img-fluid"
            src="./../../logos/royalLogo.jpg"
          ></img>
          <img
            className="p-2 img-fluid"
            src="./../../logos/rioGrandeLogo.jpg"
          ></img>
          <img
            className="p-2 img-fluid"
            src="./../../logos/paseosLogo.jpg"
          ></img>
          <img
            className="p-2 img-fluid"
            src="./../../logos/palmasLogo.jpg"
          ></img>
        </div>
        <div className="d-xl-flex justify-content-between">
          <img className="p-2 img-fluid" src="./../../logos/turboLogo.jpg"></img>
          <img className="p-2 img-fluid" src="./../../logos/amalLogo.jpg"></img>
          <img className="p-2 img-fluid" src="./../../logos/aguasLogo.jpg"></img>
          <img className="p-2 img-fluid" src="./../../logos/berrinLogo.jpg"></img>
        </div>
      */}
        <div className="container">
          <div className="row justify-content-center mb-2">
            <div className="col-md-3 col-sm-6 mb-sm-0 mb-2">
              <div className="d-flex justify-content-center">
                <img
                  className="img-fluid"
                  src="./../../logos/royalLogo.jpg"
                  alt="royal logo"
                ></img>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 my-sm-0 my-2">
              <div className="d-flex justify-content-center">
                <img
                  className="img-fluid"
                  src="./../../logos/rioGrandeLogo.jpg"
                  alt="rio grande logo"
                ></img>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 my-sm-0 my-2">
              <div className="d-flex justify-content-center">
                <img
                  className="img-fluid"
                  src="./../../logos/paseosLogo.jpg"
                  alt="paseos logo"
                ></img>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 my-sm-0 my-2">
              <div className="d-flex justify-content-center">
                <img
                  className="img-fluid"
                  src="./../../logos/palmasLogo.jpg"
                  alt="palmas logo"
                ></img>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-3 col-sm-6 my-sm-0 my-2">
              <div className="d-flex justify-content-center">
                <img
                  className="img-fluid"
                  src="./../../logos/turboLogo.jpg"
                  alt="turbo logo"
                ></img>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 my-sm-0 my-2">
              <div className="d-flex justify-content-center">
                <img
                  className="img-fluid"
                  src="./../../logos/amalLogo.jpg"
                  alt="amal logo"
                ></img>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 my-sm-0 my-2">
              <div className="d-flex justify-content-center">
                <img
                  className="img-fluid"
                  src="./../../logos/aguasLogo.jpg"
                  alt="aguas logo"
                ></img>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 my-sm-0 my-2">
              <div className="d-flex justify-content-center">
                <img
                  className="img-fluid"
                  src="./../../logos/berrinLogo.jpg"
                  alt="berrin logo"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Clients;
