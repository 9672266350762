import React from "react";

function Footer() {
  return (
    <footer className="py-5 mt-auto">
      <p className="text-center text-muted">COPYRIGHT © 2022 REKONSI - ALL RIGHTS RESERVED.</p>
    </footer>
  );
}

export default Footer;
