import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React from "react";

function MainNavigation() {
  const { t, i18n } = useTranslation();
  function TranslateClick(lang) {
    i18n.changeLanguage(lang);
  }
  return (
    <header>
      <div className="d-flex p-2 justify-content-end">
        <div>
          <a href="https://portal.rekonsi.com/">
            <button type="button" className="btn btn-primary mx-3">
              {t("body.login")}
            </button>
          </a>
        </div>
        <div className="d-flex p-2 justify-content-end">
          <img
            onClick={() => TranslateClick("esp")}
            className="object-fit-scale  "
            alt="PR-flag"
            src=".././sp-flag.png"
            role="button"
          ></img>
          <img
            onClick={() => TranslateClick("en")}
            className="mx-1 object-fit-scale  "
            alt="US-flag"
            src=".././en-flag.png"
            role="button"
          ></img>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg py-3">
        <div className="container">
          <Link className="nav-link" to="/">
            <img
              width="235"
              length="135"
              className="navbar-brand"
              src=".././gallery/Rekonsi-Final.svg"
              alt="REKONSI"
            ></img>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navmenu"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navmenu">
            <ul className="navbar-nav ms-auto flex-wrap">
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  {t("navigation.home")}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/advantages">
                  {t("navigation.advantages")}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/how-it-works">
                  {t("navigation.howItWorks")}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/clients">
                  {t("navigation.clients")}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/pricing">
                  {t("navigation.pricing")}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about-us">
                  {t("navigation.aboutUs")}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/faq">
                  {t("navigation.faq")}
                </Link>
              </li>
                          <li className="nav-item">

                              <a className="nav-link" target="_blank" href="/RekonsiManual/index.html">
                  {t("navigation.support")}
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact-us">
                  {t("navigation.contactUs")}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/privacy">
                  {t("PRIVACY POLICY")}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default MainNavigation;
