import { useTranslation } from "react-i18next";
import React from "react";

function AdvantageItem(props) {
  const { t } = useTranslation();
  return (
    <div
      className={
        "d-sm-flex justify-content-between mt-5 mb-5 " +
        (props.advantageItem.id % 2 === 1 ? "main-back-ground" : "")
      }
    >
      <div
        className={
          "pt-5 ps-5 pe-3 " + (props.advantageItem.id % 2 === 1 ? "order-1" : "order-2")
        }
      >
        <h4 className="sub-header-dark">{t(props.advantageItem.name)}</h4>
        <p className="paragraph-font">{t(props.advantageItem.description)}</p>
      </div>
      <div className={props.advantageItem.id % 2 === 1 ? "order-2" : "order-1"}>
        <img
          className="img-fluid"
          width={320}
          height={200}
          src={props.advantageItem.image}
          alt="advantage item"
        ></img>
      </div>
    </div>
  );
}

export default AdvantageItem;
