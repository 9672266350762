import { useTranslation } from "react-i18next";
import React, { useState } from "react";
function Schedule() {
  const { t } = useTranslation();
  const [isMenuExpanded, setMenuExpanded] = useState(false);
  function buildExpression(day, today) {
    let expression1 = "";
    let expression2 = "";
    if (
      day.localeCompare("saturday") === 0 ||
      day.localeCompare("sunday") === 0
    ) {
      expression1 = t("body." + day + "Short");
      expression2 = t("body.closed");
    } else {
      expression1 = t("body." + day + "Short");
      expression2 = " 9:00 am - 5:00 pm";
    }
    return day.localeCompare(today) === 0 ? (
      <React.Fragment>
        <td>
          <b>{expression1}</b>
        </td>
        <td className="ps-1">
          <b>{expression2}</b>
        </td>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <td>{expression1}</td>
        <td className="ps-1">{expression2}</td>
      </React.Fragment>
    );
  }
  function buildSchedule(isExpanded) {
    const today = new Date()
      .toLocaleTimeString("en-us", { weekday: "long" })
      .split(" ")[0]
      .toLocaleLowerCase();
    if (isExpanded === false) {
      let dayMessage = "";
      if (
        today.localeCompare("saturday") === 0 ||
        today.localeCompare("sunday") === 0
      ) {
        dayMessage = "Today " + t("body.closed");
      } else {
        dayMessage = t("body.openToday") + " 09:00 am – 05:00 pm";
      }
      return (
        <tr>
          <td
            className="paragraph-font"
            role="button"
            onClick={() => setMenuExpanded(true)}
          >
            {dayMessage}
          </td>
        </tr>
      );
    }
    const days = [
      { id: 1, day: "monday" },
      { id: 2, day: "tuesday" },
      { id: 3, day: "wednesday" },
      { id: 4, day: "thursday" },
      { id: 5, day: "friday" },
      { id: 6, day: "saturday" },
      { id: 7, day: "sunday" },
    ];

    return days.map((day) => {
      return (
        <tr
          key={day.id}
          role="button"
          className="paragraph-font d-block"
          onClick={() => setMenuExpanded(false)}
        >
          {buildExpression(day.day, today)}
        </tr>
      );
    });
  }
  return (
    <div>
      <p className="paragraph-font mb-5">{t("body.tellUs")}</p>
      <h4 className="sub-header-dark mb-3">Rekonsi</h4>
      <h6 className="sub-header-font mb-3">info@rekonsi.com</h6>
      <h4 className="sub-header-dark mb-3">{t("body.hours")}</h4>
      <div className="mb-4">
        <table>
          <tbody>{buildSchedule(isMenuExpanded)}</tbody>
        </table>
      </div>

      <h6 className="paragraph-font mb-1">
        {t("body.monday")} - {t("body.friday")}: 9:00 am - 5:00 pm
      </h6>
      <h6 className="paragraph-font">
        {t("body.saturday")} - {t("body.sunday")}: {t("body.closed")}
      </h6>
    </div>
  );
}
export default Schedule;
