import { useTranslation } from "react-i18next";
import Video from "../components/video/video";
import React from "react";

function HowItWorks() {
  const { t } = useTranslation();
  return (
    <div className="container mb-5">
      <div>
        <h1 className="mb-5 header-font">
          {t("body.howItWorks.title")}
        </h1>
        <div className="mb-5">
          <h5 className="mb-3 sub-header-font wide-text">
            {t("body.howItWorks.howDesktopWorks")}
          </h5>
          <h5 className="mb-5 paragraph-font">
            {t("body.howItWorks.desktopWorkComment")}
          </h5>
          <div className="row justify-content-md-center">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                          <Video youtubeID="hJkqmF1d2hM" />
            </div>
          </div>
        </div>
        <div className="pt-5">
          <h5 className="mb-3 sub-header-font wide-text">
            {t("body.howItWorks.howPortalWorks")}
          </h5>
          <h5 className="mb-5 paragraph-font">
            {t("body.howItWorks.portalWorkComment")}
          </h5>
          <div className="row justify-content-md-center">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                          <Video youtubeID="xxTOVrvTeKU" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default HowItWorks;
