import { useTranslation } from "react-i18next";
import React from "react";

function Advantages() {
  //<div dangerouslySetInnerHTML={{ __html: t("body.advantagesList") }}></div>
  const { t } = useTranslation();
  return (
    <div className="container mb-5">
      <h1 className="mb-5 header-font">{t("body.advantages.title")}</h1>
      <ul>
        <li>
          <p className="paragraph-font">{t("body.advantages.page.adv1")}</p>
        </li>
        <div className="d-flex justify-content-center mb-4 mt-4">
          <img
            className="img-fluid"
            width={640}
            height={400}
            src="./../../advantages/page/adv1.png"
            alt="advantages 1"
          ></img>
        </div>

        <li>
          <p className="paragraph-font">{t("body.advantages.page.adv2")}</p>
        </li>

        <li>
          <p className="paragraph-font">{t("body.advantages.page.adv3")}</p>
        </li>
        <div className="d-flex justify-content-center mb-4 mt-4">
          <img
            className="img-fluid"
            width={640}
            height={400}
            src="./../../advantages/page/adv2.png"
            alt="advantages 2"
          ></img>
        </div>

        <li>
          <p className="paragraph-font">{t("body.advantages.page.adv4")}</p>
        </li>
        <div className="d-flex justify-content-center mb-4 mb-4">
          <img
            className="img-fluid"
            width={800}
            height={500}
            src="./../../advantages/page/adv3.png"
            alt="advantages 3"
          ></img>
        </div>

        <li>
          <p className="paragraph-font">{t("body.advantages.page.adv5")}</p>
        </li>

        <li>
          <p className="paragraph-font">{t("body.advantages.page.adv6")}</p>
        </li>
        <div className="mb-4 mt-4">
          <div className="d-flex justify-content-center">
            <img
              className="img-fluid"
              width={240}
              height={150}
              src="./../../advantages/page/adv4.png"
              alt="advantages 4"
            ></img>
            <p className="paragraph-font ms-4">
              {t("body.advantages.page.adv13")}
            </p>
          </div>
          <div className="d-flex justify-content-center">
            <img
              className="img-fluid"
              width={640}
              height={400}
              src="./../../advantages/page/adv5.png"
              alt="advantages 5"
            ></img>
          </div>
        </div>
        <li>
          <p className="paragraph-font">{t("body.advantages.page.adv7")}</p>
        </li>
        <div className="d-flex justify-content-center mt-4 mb-4">
          <img
            className="img-fluid"
            width={640}
            height={400}
            src="./../../advantages/page/adv6.png"
            alt="advantages 6"
          ></img>
        </div>

        <li>
          <p className="paragraph-font">{t("body.advantages.page.adv8")}</p>
        </li>
        <div className="d-flex justify-content-center mt-4 mb-4">
          <img
            className="img-fluid"
            width={640}
            height={400}
            src="./../../advantages/page/adv7.png"
            alt="advantages 7"
          ></img>
        </div>

        <li>
          <p className="paragraph-font">{t("body.advantages.page.adv9")}</p>
        </li>
        <div className="d-flex justify-content-center mt-4 mb-4">
          <img
            className="img-fluid"
            width={480}
            height={300}
            src="./../../advantages/page/adv8.png"
            alt="advantages 8"
          ></img>
        </div>

        <li>
          <p className="paragraph-font">{t("body.advantages.page.adv10")}</p>
        </li>
        <div className="d-flex justify-content-center mt-4 mb-4">
          <img
            className="img-fluid"
            width={640}
            height={400}
            src="./../../advantages/page/adv9.png"
            alt="advantages 9"
          ></img>
        </div>

        <li>
          <p className="paragraph-font">{t("body.advantages.page.adv11")}</p>
        </li>
        <div className="d-flex justify-content-center mt-4 mb-4">
          <img
            className="img-fluid"
            width={640}
            height={400}
            src="./../../advantages/page/adv10.png"
            alt="advantages 10"
          ></img>
        </div>

        <li>
          <p className="paragraph-font">{t("body.advantages.page.adv12")}</p>
        </li>
        <div className="d-flex justify-content-center mt-4 mb-4">
          <img
            className="img-fluid"
            width={640}
            height={400}
            src="./../../advantages/page/adv11.png"
            alt="advantages 11"
          ></img>
        </div>
      </ul>
    </div>
  );
}

export default Advantages;
