import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Schedule from "../components/contact/schedule";
import EmailForm from "../components/contact/emailForm";

function ContactUs() {
  const { t } = useTranslation();
  const [isContactOpen, setContactState] = useState(false);

  return (
    <div className="container mb-5">
      <h1 className="mb-5 header-font">{t("body.contactUs")}</h1>
      <div className="d-flex">
        {!isContactOpen && (
          <div className="w-50 pe-4">
            <Schedule />
            <div className="mt-4 d-sm-block d-flex justify-content-end">
              <button
                className="button-grey wide"
                onClick={() => setContactState(true)}
              >
                {t("body.forQuestions")}
              </button>
            </div>
          </div>
        )}
        {isContactOpen && (
          <div className="w-50 pe-4">
            <EmailForm onCancel ={setContactState}/>
          </div>
        )}
        <div className="w-50">
          <img
            className="w-100"
            src="https://img1.wsimg.com/isteam/stock/uENxzPOagDu35BQJA/:/rs=w:984"
            alt="Working dude"
          ></img>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
