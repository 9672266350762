import { useTranslation } from "react-i18next";
import React from "react";

function EmailForm(props) {
  function capitalize(phrase) {
    return phrase
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");
  }
  const { t } = useTranslation();
  return (
    <div>
      <h4 className="sub-header-dark mb-4">
        {capitalize(t("body.forQuestions"))}
      </h4>
      <form className="w-75">
        <div className="mb-3">
          <input
            type="text"
            className="form-control form-control-lg"
            id="name"
            placeholder={t("body.name")}
          />
        </div>
        <div className="mb-3">
          <input
            type="email"
            className="form-control form-control-lg"
            id="email"
            placeholder={t("body.email")}
          />
        </div>
        <div className="mb-3">
          <textarea
            className="form-control form-control-lg"
            id="message"
            rows="3"
            placeholder={t("body.pleaseTellUs")}
          ></textarea>
        </div>
      </form>
      <div className="d-flex justify-content-center w-75 align-items-center">
        <button className="button-grey wide me-4">
          {t("body.send").toUpperCase()}
        </button>
        <span className="link" role="button" onClick={() => props.onCancel(false)}>
          <u>{t("body.cancel")}</u>
        </span>
      </div>
    </div>
  );
}
export default EmailForm;
